<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Habit name -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('BEHAVIOUR_NAME')"
                      label-for="id-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('BEHAVIOUR_NAME')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-task-name"
                          v-model="subTask.subtaskstring"
                          maxlength="80"
                          autofocus
                          trim
                          :placeholder="$t('BEHAVIOUR_NAME')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Points -->
                  <b-col md="6">

                    <b-form-group
                      :label="$t('POINTS')"
                      label-for="id-task-points"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('POINTS')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-task-points"
                          v-model="subTask.subtaskpoint"
                          type="number"
                          trim
                          :placeholder="$t('POINTS')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!--Sort order -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('SORT_ORDER')"
                      label-for="id-sortOrder"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SORT_ORDER')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-sortOrder"
                          v-model="subTask.sortorder"
                          maxlength="3"
                          type="number"
                          :placeholder="$t('SORT_ORDER')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Description -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('DESCRIPTION')"
                      label-for="id-description"
                    >
                      <quill-editor
                        id="id-description"
                        v-model="subTask.subtaskHelpstring"
                        maxlength="100"
                        :options="editorOption"
                        class="border-bottom-0"
                      />
                      <div
                        id="quill-toolbar"
                        class="d-flex justify-content-end border-top-0"
                      >
                        <!-- Add a bold button -->
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-align" />
                        <button class="ql-link" />
                      </div>
                    </b-form-group>
                  </b-col>
                  <!--Start value-->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('START_VALUE')"
                      label-for="id-startvalue"
                    >
                      <b-form-input
                        id="id-startvalue"
                        v-model="subTask.startValue"
                        type="number"
                        :placeholder="$t('START_VALUE')"
                      />
                    </b-form-group>
                  </b-col>
                  <!--End value -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('END_VALUE')"
                      label-for="id-endvalue"
                    >
                      <b-form-input
                        id="id-endvalue"
                        v-model="subTask.endValue"
                        type="number"
                        :placeholder="$t('END_VALUE')"
                      />
                    </b-form-group>
                  </b-col>
                  <!--charity amount -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('CHARITY_AMOUNT')"
                      label-for="id-charityamount"
                    >
                      <b-form-input
                        id="id-charityamount"
                        v-model="subTask.charity_weight"
                        type="number"
                        :placeholder="$t('CHARITY_AMOUNT')"
                      />
                    </b-form-group>
                  </b-col>
                  <!--Radio tag -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('RADIO_TAG')"
                      label-for="id-radio-tag"
                    >
                      <b-form-input
                        id="id-radio-tag"
                        v-model="subTask.radiotag"
                        maxlength="30"
                        :placeholder="$t('RADIO_TAG')"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- post video -->
                  <b-col
                    v-show="media.newmediapath"
                    md="12"
                    cols="12"
                    class="mb-2 mt-2"
                  >
                    <b-embed
                      type="iframe"
                      :src="media.newmediapath"
                      allowfullscreen
                      class="rounded mb-50"
                    />
                    <div class="icon-with-text mt-2">
                      <span class="text  mr-1">Media : {{ media.header }} ({{ media.mediaID }})</span>
                    </div>
                  </b-col>
                  <!--charity  -->
                  <b-col
                    cols="12"
                    md="12"
                    class="mt-2"
                  >
                    <game-charity
                      :can-edit-competition="false"
                      :encrypt-id="competition.encrypt_id"
                    />
                  </b-col>
                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ subTask.taskID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton,
  BForm, BFormGroup, BFormInput, BEmbed,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import GameCharity from '../../games/game-view/GameCharity.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    GameCharity,
    BEmbed,
  },
  data() {
    return {
      subTask: {
        subtaskID: 0,
        subtaskstring: '',
        subtaskHelpstring: '',
        subtaskpoint: '',
        question: '',
        startValue: 0,
        endValue: 0,
        sortorder: 0,
        radiotag: '',
        mediaID: 0,
        charity_weight: 0,
        kpi_weight: 0,
        taskID: 0,
      },
      media: {
        mediaID: 0,
        header: '',
        url: '',
        newmediapath: '',
      },
      competition: useJwt.getContent(),
      required,
    }
  },
  setup() {
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Write your behaviour description',
    }
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()
    return {
      successMessage,
      showErrorMessage,
      editorOption,
    }
  },
  created() {
    // eslint-disable-next-line radix
    this.subTask.subtaskID = parseInt(router.currentRoute.query.subtaskId)
    // eslint-disable-next-line radix
    this.subTask.taskID = parseInt(router.currentRoute.params.id)
    if (this.subTask.subtaskID !== 0) {
      this.fetchSubActivityByID(this.subTask.subtaskID).then(response => {
        if (response) {
          this.subTask = this.getSubActivity.subtask
          this.subTask.taskID = this.getSubActivity.taskid
          this.subTask.sortorder = this.getSubActivity.sortorder
          if (this.subTask.mediaID) this.fetchMedia(this.subTask.mediaID)
        }
      })
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('activity', ['getSubActivity']),
    ...mapGetters('media', ['getMedia']),
  },
  methods: {
    ...mapActions('activity', ['createSubActivity', 'updateSubActivity', 'fetchSubActivityByID']),
    ...mapActions('media', ['fetchMediaByID']),
    fetchMedia(id) {
      this.fetchMediaByID(id).then(response => {
        if (response) {
          this.media = this.getMedia
        }
      })
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          const payload = { ...this.subTask }
          if (this.subTask.subtaskID) {
            this.updateSubActivity({ subTask: payload }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.BEHAVIOUR_UPDATED'))
                this.$router.push({ name: 'activity-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.createSubActivity({ task: payload, maintaskId: this.subTask.taskID }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.BEHAVIOUR_CREATED'))
                this.$router.push({ name: 'activity-list' })
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
@import '@core/scss/vue/libs/quill.scss';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

</style>
